import React, { useEffect } from "react";
import MediaHeading from "./MediaHeading";
import LatestAlbums from "./LatestAlbums.jsx";
import LatestAudio from "./LatestAudio.jsx";
import LatestVideos from "./LatestVideos.jsx";
import LatestGallery from "./LatestGallery.jsx";
import "./styles/Latest.css";

export default function Latest({
  playingType,
  setPlayingType,
  setAlbumView,
  section,
  currentMedia,
  setCurrentMedia,

  /* Tracks */
  toMinutes,
  openPlayer,
  setList,
  playing,
  currentTime,
  setPlaying,
  currentPlaying,
  setCurrentPlaying,
  tracks,
  albums,

  /* Videos */
  videoIndex,
  setVideoIndex,
  videoPlaying,
  setVideoPlaying,
  videos,
  mouseDown,

  /* Gallery */
  images,
  setGalleryIndex,
  setGalleryOpen,
}) {
  useEffect(() => {
    switch (section) {
      case "albums":
        setTimeout(() => {
          window.scrollBy(
            0,
            document.querySelector(".latest-albums").getBoundingClientRect()
              .top - 10
          );
        }, 100);
        break;
      case "audio":
        setTimeout(() => {
          window.scrollBy(
            0,
            document.querySelector(".latest-audio").getBoundingClientRect()
              .top - 10
          );
        }, 100);
        break;
      case "videos":
        setTimeout(() => {
          window.scrollBy(
            0,
            document.querySelector(".latest-videos").getBoundingClientRect()
              .top - 10
          );
        }, 100);
        break;
      case "gallery":
        setTimeout(() => {
          window.scrollBy(
            0,
            document.querySelector(".latest-gallery").getBoundingClientRect()
              .top - 10
          );
        }, 100);
        break;
      default:
        break;
    }
  }, [section]);

  return (
    <div className="media-latest">
      <section className="latest-albums">
        <MediaHeading
          href="https://jvkstudios.com/media?=latest&albums"
          link={{
            url: "/media?=albums",
            text: "VIEW ALL",
            action: (e) => {
              e.preventDefault();
              setCurrentMedia("albums");
              window.history.pushState("", "", "/media?=albums");
              window.scrollTo({ top: 0, left: 0, behavior: "instant" });
            },
          }}
        >
          LATEST ALBUMS
        </MediaHeading>
        <LatestAlbums
          albums={albums}
          setAlbumView={setAlbumView}
          setCurrentMedia={setCurrentMedia}
          toMinutes={toMinutes}
          tracks={tracks}
        />
      </section>
      <section className="latest-audio">
        <MediaHeading
          href="https://jvkstudios.com/media?=latest&audio"
          link={{
            url: "/media?id=a05&audio",
            text: "VIEW ALL",
            action: (e) => {
              e.preventDefault();
              window.history.pushState("", "", "/media?id=a05&audio");
              setAlbumView(4);
              setCurrentMedia("album");
              setTimeout(() => {
                window.scrollTo({ top: 0, left: 0, behavior: "instant" });
                window.scrollBy(
                  0,
                  document
                    .querySelector("#album-tracks")
                    .getBoundingClientRect().top - 10
                );
              }, 100);
            },
          }}
        >
          LATEST AUDIO
        </MediaHeading>
        <LatestAudio
          toMinutes={toMinutes}
          openPlayer={openPlayer}
          setList={setList}
          playing={playing}
          currentTime={currentTime}
          setPlaying={setPlaying}
          currentPlaying={currentPlaying}
          setCurrentPlaying={setCurrentPlaying}
          tracks={tracks}
          albums={albums}
          viewAlbum={setAlbumView}
          setCurrentMedia={setCurrentMedia}
        />
      </section>
      <section className="latest-videos">
        <MediaHeading
          href="https://jvkstudios.com/media?=latest&videos"
          link={{
            url: "/media?id=a05&videos",
            text: "VIEW ALL",
            action: (e) => {
              e.preventDefault();
              window.history.pushState("", "", "/media?id=a05&videos");
              setAlbumView(4);
              setCurrentMedia("album");
              setTimeout(() => {
                window.scrollTo({ top: 0, left: 0, behavior: "instant" });
                window.scrollBy(
                  0,
                  document
                    .querySelector("#album-videos")
                    .getBoundingClientRect().top - 10
                );
              }, 100);
            },
          }}
        >
          LATEST VIDEOS
        </MediaHeading>
        <LatestVideos
          index={videoIndex}
          setIndex={setVideoIndex}
          playing={videoPlaying}
          setPlaying={setVideoPlaying}
          playingType={playingType}
          setPlayingType={setPlayingType}
          toMinutes={toMinutes}
          videos={videos}
          albums={albums}
          mouseDown={mouseDown}
          viewAlbum={setAlbumView}
          setCurrentMedia={setCurrentMedia}
        />
      </section>
      <section className="latest-gallery">
        <MediaHeading
          href="https://jvkstudios.com/media?=latest&gallery"
          link={{
            url: "/media?id=a05&gallery",
            text: "VIEW ALL",
            action: (e) => {
              e.preventDefault();
              window.history.pushState("", "", "/media?id=a05&gallery");
              setAlbumView(4);
              setCurrentMedia("album");
              setTimeout(() => {
                window.scrollTo({ top: 0, left: 0, behavior: "instant" });
                window.scrollBy(
                  0,
                  document
                    .querySelector("#album-gallery")
                    .getBoundingClientRect().top - 10
                );
              }, 100);
            },
          }}
        >
          LATEST GALLERY
        </MediaHeading>
        <LatestGallery
          images={images}
          albums={albums}
          viewAlbum={setAlbumView}
          setCurrentMedia={setCurrentMedia}
          setGalleryIndex={setGalleryIndex}
          setGalleryOpen={setGalleryOpen}
        />
      </section>
    </div>
  );
}
