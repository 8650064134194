import React, { useState } from "react";
import MediaHeading from "../MediaHeading";
import PlayingVideo from "../PlayingVideo";
import NextVideos from "../NextVideos";
import "./styles/Videos.css";

export default function Videos({
  videos,
  id,
  index,
  setIndex,
  playing,
  setPlaying,
  albums,
  playingType,
  setPlayingType,
  toMinutes,
  mouseDown,
  viewAlbum,
  setCurrentMedia,
  requestScrollToVideos,
  requestVideoPlay,
}) {
  const [videoM, setVideoM] = useState(null);

  return (
    <section id="album-videos">
      <MediaHeading
        href={`https://jvkstudios.com/media?id=a${(id + 1)
          .toString()
          .padStart(2, "0")}&videos`}
        link={{
          url: "/media?id=a05&videos",
          text: "VIEW ALL",
          action: (e) => {
            e.preventDefault();
            window.history.pushState("", "", "/media?id=a05&videos");
            viewAlbum(4);
            setCurrentMedia("album");
            setTimeout(() => {
              window.scrollBy(
                0,
                document.querySelector("#album-videos").getBoundingClientRect()
                  .top - 10
              );
            }, 100);
          },
        }}
      >
        VIDEOS
      </MediaHeading>
      {videos[0] !== "VIDEOS_NOT_YET_LOADED" &&
      videos.length !== 0 &&
      albums[0] !== "ALBUMS_NOT_YET_LOADED" ? (
        <>
          <PlayingVideo
            length={videos.length}
            playingType={playingType}
            setPlayingType={setPlayingType}
            videoM={videoM}
            setVideoM={setVideoM}
            playing={playing}
            setPlaying={setPlaying}
            videoCount={videos.length}
            toMinutes={toMinutes}
            index={index}
            setIndex={setIndex}
            title={videos[index].title}
            description={videos[index].description}
            link={videos[index].link}
            album={albums[videos[index].album].title}
            copyright={`PROPERTY OF<br />${videos[index].copyright}`}
            thumbnail={videos[index].thumbnail}
            youtube={videos[index].youtube}
            views={videos[index].views}
            date={videos[index].date}
            rating={videos[index].rating}
            filePath={videos[index].filePath}
            mouseDown={mouseDown}
            viewAlbum={() => {
              viewAlbum(videos[index].album);
              setCurrentMedia("album");
            }}
            requestScrollToVideos={requestScrollToVideos}
            requestVideoPlay={requestVideoPlay}
          />
          <NextVideos
            albums={albums}
            setPlayingType={setPlayingType}
            setVideoM={setVideoM}
            setPlaying={setPlaying}
            videos={videos}
            index={index}
            setIndex={setIndex}
            toMinutes={toMinutes}
            viewAlbum={viewAlbum}
            setCurrentMedia={setCurrentMedia}
          />
        </>
      ) : (
        <>
          <div className="playing-video-loader">
            <div>
              <div>
                <div className="loading-title"></div>
                <div className="loading-album"></div>
              </div>
              <div className="loading-copyright"></div>
            </div>
            <div className="loading-main-video"></div>
            <div>
              <div className="loading-icons"></div>
              <div className="loading-icons"></div>
            </div>
            <div className="loading-description"></div>
          </div>
          <div className="video-loader">
            <div className="loading-video">
              <div className="loading-video_image"></div>
              <div className="loading-video_title"></div>
              <div className="loading-video_composer"></div>
            </div>
            <div className="loading-video">
              <div className="loading-video_image"></div>
              <div className="loading-video_title"></div>
              <div className="loading-video_composer"></div>
            </div>
            <div className="loading-video">
              <div className="loading-video_image"></div>
              <div className="loading-video_title"></div>
              <div className="loading-video_composer"></div>
            </div>
            <div className="loading-video">
              <div className="loading-video_image"></div>
              <div className="loading-video_title"></div>
              <div className="loading-video_composer"></div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
