import React from "react";
import MediaCarousel from "./MediaCarousel.jsx";
import { SplideSlide } from "@splidejs/react-splide";
import Image from "./Image.jsx";
import "./styles/LatestGallery.css";

export default function LatestGallery({
  images,
  albums,
  viewAlbum,
  setCurrentMedia,
  setGalleryIndex,
  setGalleryOpen,
}) {
  return (
    <div id="latest-gallery">
      {images[0] !== "IMAGES_NOT_YET_LOADED" &&
      albums[0] !== "ALBUMS_NOT_YET_LOADED" &&
      images.length !== 0 ? (
        <>
          <MediaCarousel>
            {images.map((image, index) => (
              <SplideSlide>
                <Image
                  id={image.id}
                  title={image.title}
                  album={image.album}
                  thumbnail={image.thumbnail}
                  albums={albums}
                  viewAlbum={viewAlbum}
                  setCurrentMedia={setCurrentMedia}
                  index={index}
                  length={images.length}
                  openImage={() => {
                    window.scrollBy(
                      0,
                      document
                        .querySelector(".latest-gallery")
                        .getBoundingClientRect().top - 10
                    );
                    setGalleryIndex(image.id);
                    setGalleryOpen(true);
                  }}
                  images={images}
                />
              </SplideSlide>
            ))}
          </MediaCarousel>
        </>
      ) : (
        <span>loading...</span>
      )}
    </div>
  );
}