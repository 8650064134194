import React from "react";
import "./styles/FAQSection.css";

export default function FAQSection({ title, children: questions }) {
  return (
    <div className="faq-section">
      <h4>{title}</h4>
      {questions}
    </div>
  );
}