import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Slider from "../components/Slider";
import About from "../components/About";
import Contact from "../components/Contact";
import Connect from "../components/Connect";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";

export default function Home({ path, page }) {
  useEffect(() => {
    if (page === "contact") {
      document.querySelector("#contact").scrollIntoView();
    }
  }, [page ]);

  const [albums, setAlbums] = useState(["ALBUMS_NOT_YET_LOADED"]);
  const [images, setImages] = useState(["IMAGES_NOT_YET_LOADED"]);

  const [galleryIndex, setGalleryIndex] = useState(0);
  const [galleryOpen, setGalleryOpen] = useState("initial");

  useEffect(() => {
    // Fetch all albums
    fetch("/api/media/albums", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAlbums(data);
      });

    // Fetch all images and filter images labeled as latest
    fetch("/api/media/images", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const latest = data.filter((image) => image.latest);
        setImages(latest);
      });
  }, []);

  return (
    <>
      <Header />
      <Slider />
      <About
        path={page === "" ? null : page}
        setGalleryIndex={setGalleryIndex}
        setGalleryOpen={setGalleryOpen}
      />
      <Contact />
      <Connect />
      {images[0] !== "IMAGES_NOT_YET_LOADED" &&
      albums[0] !== "ALBUMS_NOT_YET_LOADED" ? (
        <Gallery
          path={path}
          images={images}
          albums={albums}
          index={galleryIndex}
          setIndex={setGalleryIndex}
          isOpen={galleryOpen}
          close={() => setGalleryOpen(false)}
        />
      ) : (
        <></>
      )}
      <Footer />
    </>
  );
}
