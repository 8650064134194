import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Search from "../components/Search";
import MediaMain from "../components/MediaMain.jsx";
import MediaPlayer from "../components/MediaPlayer";
import Footer from "../components/Footer.jsx";
import "../components/styles/Media.css";
import Gallery from "../components/Gallery.jsx";

export default function Home({ path, setPath, page }) {
  // null, "audio", or "video"
  const [playingType, setPlayingType] = useState(null);
  const [playing, setPlaying] = useState(false);
  // eslint-disable-next-line
  const [currentTime, setCurrentTime] = useState(0);
  const [changeTime, setChangeTime] = useState(null);
  // eslint-disable-next-line
  const [shuffle, setShuffle] = useState(false);
  const [loop, setLoop] = useState(0);
  const [autoplay, setAutoplay] = useState(true);
  const [list, setList] = useState([]);
  const [volume, setVolume] = useState(100);
  const [currentPlaying, setCurrentPlaying] = useState(null);
  const [currentMedia, setCurrentMedia] = useState("latest");

  const [tracks, setTracks] = useState(["TRACKS_NOT_YET_LOADED"]);
  const [albums, setAlbums] = useState(["ALBUMS_NOT_YET_LOADED"]);
  const [videos, setVideos] = useState(["VIDEOS_NOT_YET_LOADED"]);
  const [images, setImages] = useState(["IMAGES_NOT_YET_LOADED"]);

  const [albumID, setAlbumID] = useState(null);

  const [galleryIndex, setGalleryIndex] = useState(0);
  const [galleryOpen, setGalleryOpen] = useState("initial");

  useEffect(() => {
    // Fetch all tracks and filter tracks labeled as latest
    fetch("/api/media/tracks", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const latest = data.filter((track) => track.latest);
        setTracks(latest);
      });

    // Fetch all albums
    fetch("/api/media/albums", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAlbums(data);
      });

    // Fetch all videos and filter videos labeled as latest
    fetch("/api/media/videos", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const latest = data.filter((video) => video.latest);
        setVideos(latest);
      });

    // Fetch all images and filter images labeled as latest
    fetch("/api/media/images", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const latest = data.filter((image) => image.latest);
        setImages(latest);
      });
  }, []);

  const [audio] = useState(new Audio());
  const [previousTrack, setPreviousTrack] = useState(null);

  // No idea what this is. Probably shouldn't touch it
  useEffect(() => {
    if (currentPlaying !== null && previousTrack !== currentPlaying) {
      audio.pause();
      setCurrentTime(0);
      audio.src =
        tracks[
          tracks.findIndex((track) => track.id === currentPlaying)
        ].filePath;
      if (playing) audio.play();
      setPlayingType("audio");
    }
    setPreviousTrack(currentPlaying);
  }, [audio, currentPlaying, tracks, list, previousTrack, playing]);

  // Update current time of playing track every 100ms
  useEffect(() => {
    let progress;
    if (playing) {
      audio.play();
      progress = setInterval(() => {
        setCurrentTime(audio.currentTime);
      }, 100);
    } else {
      audio.pause();
      clearInterval(progress);
    }
  }, [playing, audio]);

  // Set audio attributes
  useEffect(() => {
    audio.volume = volume / 100;
  }, [audio, volume]);
  useEffect(() => {
    if (loop === 1) {
      audio.loop = true;
    } else {
      audio.loop = false;
    }
  }, [audio, loop]);
  useEffect(() => {
    audio.currentTime = changeTime;
  }, [audio, changeTime]);

  useEffect(() => {
    // When a track has finished playing...
    audio.onended = () => {
      if (loop !== 1) {
        if (autoplay) {
          if (!shuffle) {
            // ...if loop is off, autoplay is on, shuffle is off, and the current track is not the last track...
            if (list.indexOf(currentPlaying) !== list.length - 1) {
              // ...play the next track...
              setCurrentPlaying(list[list.indexOf(currentPlaying) + 1]);
            } else {
              if (loop === 2) {
                setCurrentPlaying(list[0]);
              } else {
                // ...otherwise stop playing
                setPlaying(false);
              }
            }
          } else {
            // ...if loop is off, autoplay is on, and shuffle is on, select a random track to play...
            let nextTrack = list[Math.floor(Math.random() * list.length)];
            // ...that was not the last played track
            while (nextTrack === currentPlaying) {
              nextTrack = list[Math.floor(Math.random() * list.length)];
            }
            setCurrentPlaying(nextTrack);
          }
        } else {
          // ...if autoplay is off, stop playing
          setPlaying(false);
        }
      }
    };
  }, [audio, loop, autoplay, shuffle, list, currentPlaying]);

  // Store state of mouse button
  const [mouseDown, setMouseDown] = useState(false);
  useEffect(() => {
    document.addEventListener("mousedown", () => setMouseDown(true));
    document.addEventListener("mouseup", () => setMouseDown(false));
  }, []);

  // Function converts seconds to time in the format mm:ss
  function toMinutes(seconds) {
    return `${Math.floor(seconds / 60)}:${Math.round(seconds % 60)
      .toString()
      .padStart(2, "0")}`;
  }

  // Open the media player at the bottom of the page
  function openPlayer() {
    document.querySelector("#player").style.bottom = "0";
    document.querySelector("#player > .progress-bar").style.pointerEvents =
      "initial";
  }

  // Keyboard shortcuts for the media player
  const [wait, setWait] = useState(false);
  document.onkeydown = (e) => {
    if (
      list.length !== 0 &&
      currentPlaying !== null &&
      e.target === document.querySelector("#player")
    ) {
      switch (e.key) {
        case " ":
          e.preventDefault();
          if (!wait) {
            setWait(true);
            setPlaying(!playing);
            setTimeout(() => setWait(false), 200);
          }
          break;
        case "ArrowLeft":
          e.preventDefault();

          if (audio.currentTime <= 5) {
            audio.currentTime = 0;
          } else {
            audio.currentTime = audio.currentTime - 5;
          }
          break;
        case "ArrowRight":
          e.preventDefault();
          if (
            audio.currentTime >=
            tracks[tracks.findIndex((track) => track.id === currentPlaying)]
              .duration -
              5
          ) {
            audio.currentTime =
              tracks[
                tracks.findIndex((track) => track.id === currentPlaying)
              ].duration;
          } else {
            audio.currentTime = audio.currentTime + 5;
          }
          break;
        default:
          break;
      }
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState({
    albums: null,
    tracks: null,
    videos: null,
  });

  // Get search results based on search query
  useEffect(() => {
    if (
      albums !== "ALBUMS_NOT_YET_LOADED" &&
      tracks !== "TRACKS_NOT_YET_LOADED" &&
      videos !== "VIDEOS_NOT_YET_LOADED" &&
      searchQuery !== ""
    ) {
      const albumResults = albums.filter((album) => {
        return album.title.toLowerCase().includes(searchQuery);
      });
      const trackResults = tracks.filter((track) => {
        return track.title.toLowerCase().includes(searchQuery);
      });
      const videoResults = videos.filter((video) => {
        return video.title.toLowerCase().includes(searchQuery);
      });

      setSearchResults({
        albums: albumResults.length !== 0 ? albumResults : null,
        tracks: trackResults.length !== 0 ? trackResults : null,
        videos: videoResults.length !== 0 ? videoResults : null,
      });
    } else {
      setSearchResults({
        albums: null,
        tracks: null,
        videos: null,
      });
    }
  }, [albums, tracks, videos, searchQuery]);

  return (
    <>
      <Header />
      <Search
        query={setSearchQuery}
        results={searchResults}
        albums={albums}
        viewAlbum={setAlbumID}
      />
      <main id="media">
        {albums[0] !== "ALBUMS_NOT_YET_LOADED" &&
        tracks[0] !== "TRACKS_NOT_YET_LOADED" &&
        videos[0] !== "VIDEOS_NOT_YET_LOADED" &&
        images[0] !== "IMAGES_NOT_YET_LOADED" ? (
          <MediaMain
            currentMedia={currentMedia}
            setCurrentMedia={setCurrentMedia}
            setPath={setPath}
            playingType={playingType}
            setPlayingType={setPlayingType}
            albumID={albumID}
            setAlbumID={setAlbumID}
            /* Latest tracks */
            //path={path}
            toMinutes={toMinutes}
            openPlayer={openPlayer}
            setList={setList}
            playing={playing}
            currentTime={currentTime ? currentTime : 0}
            setPlaying={setPlaying}
            currentPlaying={currentPlaying}
            setCurrentPlaying={setCurrentPlaying}
            tracks={tracks}
            albums={albums}
            /* Latest videos */
            videos={videos}
            mouseDown={mouseDown}
            /* Gallery */
            images={images.filter((image) => image.id >= 1)}
            setGalleryIndex={setGalleryIndex}
            setGalleryOpen={setGalleryOpen}
          />
        ) : (
          <></>
        )}
      </main>
      {albums[0] !== "ALBUMS_NOT_YET_LOADED" &&
      tracks[0] !== "TRACKS_NOT_YET_LOADED" &&
      videos[0] !== "VIDEOS_NOT_YET_LOADED" &&
      images[0] !== "IMAGES_NOT_YET_LOADED" ? (
        <MediaPlayer
          albumID={
            currentPlaying !== null
              ? tracks[tracks.findIndex((track) => track.id === currentPlaying)]
                  .album
              : null
          }
          playingType={playingType}
          tracks={tracks}
          toMinutes={toMinutes}
          playing={playing}
          setPlaying={setPlaying}
          currentPlaying={currentPlaying}
          setCurrentPlaying={setCurrentPlaying}
          currentTime={currentTime ? currentTime : 0}
          duration={
            currentPlaying !== null
              ? tracks[tracks.findIndex((track) => track.id === currentPlaying)]
                  .duration
              : null
          }
          image={
            currentPlaying !== null
              ? albums[
                  tracks[
                    tracks.findIndex((track) => track.id === currentPlaying)
                  ].album
                ].iconPath
              : null
          }
          title={
            currentPlaying !== null
              ? tracks[tracks.findIndex((track) => track.id === currentPlaying)]
                  .title
              : null
          }
          album={
            currentPlaying !== null
              ? albums[
                  tracks[
                    tracks.findIndex((track) => track.id === currentPlaying)
                  ].album
                ].title
              : null
          }
          viewAlbum={() => {
            setAlbumID(
              currentPlaying !== null
                ? tracks[
                    tracks.findIndex((track) => track.id === currentPlaying)
                  ].album
                : null
            );
            setCurrentMedia("album");
          }}
          shuffle={shuffle}
          setShuffle={setShuffle}
          loop={loop}
          setLoop={setLoop}
          autoplay={autoplay}
          setAutoplay={setAutoplay}
          list={list}
          setList={setList}
          volume={volume}
          setVolume={setVolume}
          setChangeTime={setChangeTime}
          mouseDown={mouseDown}
        />
      ) : (
        <></>
      )}
      {images[0] !== "IMAGES_NOT_YET_LOADED" &&
      albums[0] !== "ALBUMS_NOT_YET_LOADED" ? (
        <Gallery
          path={path}
          images={images}
          albums={albums}
          index={galleryIndex}
          setIndex={setGalleryIndex}
          isOpen={galleryOpen}
          close={() => setGalleryOpen(false)}
          viewAlbum={() => {
            setAlbumID(images[galleryIndex].album);
            setCurrentMedia("album");
            setGalleryOpen(false);
          }}
        />
      ) : (
        <></>
      )}
      <Footer />
    </>
  );
}
