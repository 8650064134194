import React, { useEffect } from "react";
import MediaHeading from "../MediaHeading";
import { AudioCollection, AudioTrack } from "../AudioCollection";
import "./styles/Tracks.css";

export default function Tracks({
  id,
  albums,
  tracks,
  toMinutes,
  currentTime,
  openPlayer,
  playing,
  setPlaying,
  currentPlaying,
  setCurrentPlaying,
  setList,
  viewAlbum,
  setCurrentMedia,
  requestScrollToTracks,
}) {
  useEffect(() => {
    if (requestScrollToTracks) {
      window.scrollTo(
        0,
        document.querySelector("#album-tracks").getBoundingClientRect().top - 10
      );
    }
  }, [requestScrollToTracks])

  return (
    <section id="album-tracks">
      <MediaHeading
        href={`https://jvkstudios.com/media?id=a${(id + 1)
          .toString()
          .padStart(2, "0")}&audio`}
        link={{
          url: "/media?id=a05&audio",
          text: "VIEW ALL",
          action: (e) => {
            e.preventDefault();
            window.history.pushState("", "", "/media?id=a05&audio");
            viewAlbum(4);
            setCurrentMedia("album");
            setTimeout(() => {
              window.scrollBy(
                0,
                document.querySelector("#album-tracks").getBoundingClientRect()
                  .top - 10
              );
            }, 100);
          },
        }}
      >
        AUDIO TRACKS
      </MediaHeading>
      <AudioCollection>
        {tracks[0] !== "TRACKS_NOT_YET_LOADED" &&
        albums[0] !== "ALBUMS_NOT_YET_LOADED" ? (
          tracks
            .filter((track) => track.album === id)
            .map((track, index) => (
              <AudioTrack
                key={track.id}
                id={track.id}
                index={index + 1}
                title={track.title}
                album={albums[track.album].title}
                mood={track.mood}
                duration={[
                  currentPlaying === track.id
                    ? toMinutes(currentTime)
                    : toMinutes(0),
                  toMinutes(track.duration),
                ].join(" / ")}
                rating={{ positive: 127, negative: 21 }}
                openPlayer={openPlayer}
                playing={playing}
                setPlaying={setPlaying}
                currentPlaying={currentPlaying}
                setCurrentPlaying={setCurrentPlaying}
                list={tracks
                  .filter((track) => track.album === id)
                  .map((track) => track.id)}
                setList={setList}
                tracks={tracks}
                albumID={track.album}
                viewAlbum={() => {
                  viewAlbum(track.album);
                  setCurrentMedia("album");
                }}
                date={track.date}
                views={track.views}
                description={track.description}
                copyright={track.copyright}
              />
            ))
        ) : (
          <>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
          </>
        )}
      </AudioCollection>
    </section>
  );
}