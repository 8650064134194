import React, { useEffect } from "react";
import "./styles/Legal.css";

const transitionAuto = require("@alexspirgel/transition-auto");

export default function Legal() {
  useEffect(() => {
    let currentLegal = "dis";

    function page() {
      if (new URLSearchParams(window.location.search.toLowerCase()).get("") === "disclaimer") {
        document.querySelectorAll("#legal button").forEach(el => el.classList.remove("abt-active"));
        document.querySelector("#disclaimer").classList.add("abt-active");
        document.querySelectorAll(".text-dis").forEach(el => el.style.display = "block");
        document.querySelectorAll(".text-pp").forEach(el => el.style.display = "none");
        document.querySelectorAll(".text-tos").forEach(el => el.style.display = "none");
        currentLegal = "dis";
      } else if (new URLSearchParams(window.location.search.toLowerCase()).get("") === "privacy") {
        document.querySelectorAll("#legal button").forEach(el => el.classList.remove("abt-active"));
        document.querySelector("#privacy").classList.add("abt-active");
        document.querySelectorAll(".text-dis").forEach(el => el.style.display = "none");
        document.querySelectorAll(".text-pp").forEach(el => el.style.display = "block");
        document.querySelectorAll(".text-tos").forEach(el => el.style.display = "none");
        currentLegal = "pp";
      } else if (new URLSearchParams(window.location.search.toLowerCase()).get("") === "terms") {
        document.querySelectorAll("#legal button").forEach(el => el.classList.remove("abt-active"));
        document.querySelector("#terms").classList.add("abt-active");
        document.querySelectorAll(".text-dis").forEach(el => el.style.display = "none");
        document.querySelectorAll(".text-pp").forEach(el => el.style.display = "none");
        document.querySelectorAll(".text-tos").forEach(el => el.style.display = "block");
        currentLegal = "tos";
      }
    }
    page();

    document.querySelectorAll(".year").forEach(el => el.innerHTML = new Date().getFullYear() + 1);

    //legal
    document.querySelectorAll(".legal-nav button").forEach(el => el.onclick = function() {
      document.querySelectorAll(".legal-nav button").forEach(el => el.classList.remove("abt-active"));
      this.classList.add("abt-active");
      if (this.innerHTML === "DISCLAIMER" && currentLegal !== "dis") {
        currentLegal = "dis";
        legalTransition();
        setTimeout(() => {
          document.querySelectorAll(".text-dis").forEach(el => el.style.display = "block");
          document.querySelectorAll(".text-pp").forEach(el => el.style.display = "none");
          document.querySelectorAll(".text-tos").forEach(el => el.style.display = "none");
        }, 550);
      } else if (this.innerHTML === "PRIVACY POLICY" && currentLegal !== "pp") {
        currentLegal = "pp";
        legalTransition();
        setTimeout(() => {
          document.querySelectorAll(".text-dis").forEach(el => el.style.display = "none");
          document.querySelectorAll(".text-pp").forEach(el => el.style.display = "block");
          document.querySelectorAll(".text-tos").forEach(el => el.style.display = "none");
        }, 550);
      } else if (this.innerHTML === "TERMS OF SERVICE" && currentLegal !== "tos") {
        currentLegal = "tos";
        legalTransition();
        setTimeout(() => {
          document.querySelectorAll(".text-dis").forEach(el => el.style.display = "none");
          document.querySelectorAll(".text-pp").forEach(el => el.style.display = "none");
          document.querySelectorAll(".text-tos").forEach(el => el.style.display = "block");
        }, 550);
      }

      function legalTransition() {
        document.querySelector(".outer").style.opacity = "0";
        setTimeout(() => {
          transitionAuto({
            element: document.querySelector(".outer"),
            property: "height",
            value: 0
          });
        
          setTimeout(() => {
            transitionAuto({
              element: document.querySelector(".outer"),
              property: "height",
              value: "auto"
            });

            setTimeout(() => document.querySelector(".outer").style.opacity = "1", 600);
          }, 600);
        }, 500);
      }
    });
  });

  return(   
    <section id="legal">
      <h1>LEGAL</h1>
      <div className="legal-nav">
        <button className="abt-active" id="disclaimer">DISCLAIMER</button>
        <button id="privacy">PRIVACY POLICY</button>
        <button id="terms">TERMS OF SERVICE</button>
      </div>
      <div className="outer">
        <div className="lgl-main inner">
          <div className="abt-text">
            <h2 className="text-dis">OVERVIEW</h2>
            <p className="text-dis">All the information on this website, JVK Studios, is published in good faith and for general information purpose only. JVK Studios does not make any warranties legal the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website (JVK Studios), is strictly at your own risk. JVK Studios will not be liable for any losses and/or damages in connection with the use of our website.</p>
            <p className="text-dis">From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites.</p>
            <p className="text-dis">Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.</p>
            <h2 className="text-dis">CONTACTING US</h2>
            <p className="text-dis">For more information legal our Disclaimer, if you have questions, or if you would like to make a complaint, please contact us by using the e-mail contact form on the contact page <a href="/#contact">here</a>, or by e-mailing us directly at <a href="mailto:support@jvkstudios.com">support@jvkstudios.com</a>.</p>

            <h2 className="text-pp">OVERVIEW</h2>
            <p className="text-pp">This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from JVK Studios.com (the “Website”).</p>
            <h2 className="text-pp">COLLECTION PERSONAL INFORMATION</h2>
            <p className="text-pp">We do not collect any additional personal information aside from the details you use to make a purchase.</p>
            <h2 className="text-pp">USAGE PERSONAL INFORMATION</h2>
            <p className="text-pp">The only personal information we will use is that of which you agreed to provide to make a purchase. Once the order is processed and completed, your personal information will not be used in anyway.</p>
            <h2 className="text-pp">RETAINING PERSONAL INFORMATION</h2>
            <p className="text-pp">When you place an order through the Website, we will maintain your Order Information for regarding the follow:</p>
            <ul className="text-pp">
              <li>Our records for business purposes</li>
              <li>To the extent that we are required to do so by law</li>
            </ul>
            <h2 className="text-pp">SHARING PERSONAL INFORMATION</h2>
            <p className="text-pp">We will not provide your personal information to any third parties for any reason.</p>
            <h2 className="text-pp">AMENDMENTS</h2>
            <p className="text-pp">We may update this policy from time to time by publishing a new version on our website. You should check this page occasionally to ensure you are happy with any changes to this policy.</p>
            <p className="text-pp">Last Updated: January 1st, <span className="year"></span></p>
            <h2 className="text-pp">CONTACTING US</h2>
            <p className="text-pp">For more information legal our Privacy Policy, if you have questions, or if you would like to make a complaint, please contact us by using the e-mail contact form on the contact page <a href="/#contact">here</a>, or by e-mailing us directly at <a href="mailto:support@jvkstudios.com">support@jvkstudios.com</a>.</p>

            <h2 className="text-tos">OVERVIEW</h2>
            <p className="text-tos">This website is operated by JVK Studios. Throughout the site, the terms “we”, “us” and “our” refer to JVK Studios. JVK Studios offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
            <p className="text-tos">By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”). These Terms of Service apply to all users of the site.</p>
            <p className="text-tos">Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
            <p className="text-tos">Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
            <h2 className="text-tos">COPYRIGHT & TRADEMARKS</h2>
            <p className="text-tos">By using this site you agree to not reproduce, duplicate, or modify the JVK Studios logo in any way, shape, or form including but not limited to thickness, size, color, rotation, design, etc. Unless you have express written consent from the logo Owner, JVK Studios.</p>
            <p className="text-tos">© <span className="year"></span> JVK Studios.com. All rights reserved. The JVK Studios logo are trademarks and /or registered trademarks of JVK Studios. All other trademarks are property of their respective owners.</p>
            <h2 className="text-tos">GENERAL TERMS, & DEFINITIONS</h2>
            <p className="text-tos">We reserve the right to refuse service to anyone for any reason at any time. You understand that any transmission of information over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</p>
            <p className="text-tos">You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.</p>
            <ul className="text-tos">
              <li>"Seller" means JVK Studios</li>
              <li>"Goods" means the articles that the Buyer agrees to buy from the Seller</li>
              <li>"Buyer" means the person who buys Goods from the Seller</li>
            </ul>
            <h2 className="text-tos">ONLINE STORE TERMS</h2>
            <p className="text-tos">By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>
            <p className="text-tos">You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>
            <p className="text-tos">You must not transmit any worms or viruses or any code of a destructive nature. A breach or violation of any of the Terms will result in an immediate termination of your Services. Legal action may also be taken and is entirely at the discretion of JVK Studios.</p>
            <h2 className="text-tos">PRICING & PAYMENTS</h2>
            <p className="text-tos">The price of the Goods shall be that stipulated in the Seller's current List Price/on the Seller's website/as contained in the Seller's Quotation (as applicable) at the date of order or as agreed between the parties. The price is exclusive of VAT and exclusive of any delivery charges.</p>
            <p className="text-tos">Prices for our products are subject to change without notice. We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time. We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.</p>
            <p className="text-tos">Payment of the total purchase price (including VAT and any delivery charges) must be made in full before dispatch of the Goods.</p>
            <h2 className="text-tos">DELIVERY, REFUNDS, & CANCELLATIONS</h2>
            <p className="text-tos">Delivery of the Goods shall be made by the Seller notifying the Buyer that the Goods are available for downloading via e-mail. All Goods, wherever possible, will be delivered within 7 days of the order being placed and the Buyer shall make all arrangements necessary to take delivery of the Goods whenever they are tendered for delivery.</p>
            <p className="text-tos">Once the Buyer has purchased the Goods, the sale is final. There are no refunds, unless we find your request reasonable due to special circumstances.</p>
            <p className="text-tos">Cancellations will not be accepted if the order has been already processed. Once the order is processed we may issue a refund, see refund details above.</p>
            <h2 className="text-tos">GUARANTEES</h2>
            <p className="text-tos">In addition to the Buyer's statutory rights, the Seller guarantees all Goods against faulty workmanship and materials for a period of 7 days from the date of delivery.</p>
            <h2 className="text-tos">LIABILITY</h2>
            <p className="text-tos">Nothing in these Terms and Conditions shall exclude or limit the liability of the Seller for death or personal injury, however the Seller shall not be liable for any direct loss or damage suffered by the Buyer howsoever caused, as a result of any negligence, breach of contract or otherwise in excess of the price of the Goods.</p>
            <h2 className="text-tos">CONTACTING US</h2>
            <p className="text-tos">For more information legal our Terms of Service, if you have questions, or if you would like to make a complaint, please contact us by using the e-mail contact form on the contact page <a href="/#contact">here</a>, or by e-mailing us directly at <a href="mailto:support@jvkstudios.com">support@jvkstudios.com</a>.</p>
          </div>
        </div>
      </div>
    </section>
  );
}
