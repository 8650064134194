import React, { useEffect } from "react";
import "./styles/Connect.css";

export default function Connect() {
  useEffect(() => {
    document.querySelector(".submit-newsletter").onclick = function() {
      document.querySelector(".newsletter-button").click();
    }

    document.querySelector(".newsletter-button").onclick = function() {
      document.querySelector(".ne").classList.add("inputboxcheck");

      document.getElementById("newsletter-email").addEventListener("invalid", (e) => e.preventDefault());

      checkemail();

      document.querySelector("#newsletter-email").addEventListener("input", function() {
        checkemail();
      });
    }

    function checkemail() {
      if (document.querySelector("#newsletter-email:invalid") !== null) {
        document.querySelector("#errormessagecontact").innerHTML = "<b>INPUT REQUIRED:</b> EMAIL.";
      } else {
        document.querySelector("#errormessagecontact").innerHTML = "";
      }
    }

    document.getElementById("email-form").addEventListener("submit", async function(e) {
      e.preventDefault();
      
      const email = document.querySelector("#newsletter-email").value;
      let button = document.querySelector("#newsletterID");
      let result = document.querySelector(".result-inner");

      document.querySelector("#errormessagecontact").innerHTML = "";
      button.setAttribute("disabled", true);
      document.querySelector(".ne").classList.remove("inputboxcheck");

      document.querySelector(".submit-newsletter").style.paddingLeft = "65px";
    
      setTimeout(function() {
        button.style.transition = "0.75s cubic-bezier(0.77, 0, 0.175, 1)";
        button.style.transform = "scale(5.846153846, 1.285714286)";
        setTimeout(function() {
          result.style.width = "380px";
          document.querySelector("#sendingnewsletter").style.visibility = "visible";
        }, 750)
      }, 200);

      function success() {
        let check = setInterval(function() {
          if (result.clientWidth === 380) {
            document.querySelector("#newsletter-email").value = "";
            document.querySelector("#newsletter-email").blur();

            result.style.transition = "1s";

            clearInterval(check);

            result.style.marginTop = "-36px";
            result.style.backgroundColor = "#d4edda";

            result.innerHTML = "<svg width='12' height='10' class='check' stroke='currentColor' fill='none'><polyline points='1 5.5 5 9.5 12 1.5'></polyline></svg>SUBSCRIBED!";

            setTimeout(function() {
              button.style.transform = "scale(1, 1)";
              document.querySelector("#sendingnewsletter").style.visibility = "hidden";
              document.querySelector(".submit-newsletter").style.paddingLeft = "0";
            }, 1000);

            setTimeout(function() {
              result.style.marginTop = "0";
              result.style.backgroundColor = "#d4edda";

              setTimeout(function() {
                document.querySelector("#newsletterID").removeAttribute("disabled");
                result.style.width = "0";
                setTimeout(function() {
                  result.innerHTML = "";
                  result.style.marginTop = "-4px";
                  result.style.transition = "0.75s";
                  result.style.backgroundColor = "#ffffff";
                }, 750);
              }, 1000);
            }, 1500);
          }
        }, 10);

        fetch("/api/newSubscriber", {
          method: "POST",
          headers: { "Content-Type": "text/plain" },
          body: JSON.stringify({ email: email })
        })
        .then(async res => {
          console.log(res);
        })
        .catch(async err => {
          console.error(err);
        })
      }

      function error() {
        let check = setInterval(function() {
          if (result.clientWidth === 380) {
            document.querySelector("#newsletter-email").blur();

            result.style.transition = "1s";

            clearInterval(check);

            result.style.marginTop = "-36px";
            result.style.backgroundColor = "#FF4546";
            result.innerHTML = "<svg width='10' height='10' class='x' stroke='currentColor' fill='none'><polyline points='0 0 10 10 5 5 10 0 0 10'></polyline></svg>AN ERROR OCCURED";

            setTimeout(function() {
              button.style.transform = "scale(1, 1)";

              document.querySelector("#sendingnewsletter").style.visibility = "hidden";
              document.querySelector(".submit-newsletter").style.paddingLeft = "0";
            }, 1000);

            setTimeout(function() {
              result.style.marginTop = "0";
              result.style.backgroundColor = "#FF4546";

              setTimeout(function() {
                document.querySelector("#newsletterID").removeAttribute("disabled");
                result.style.width = "0";
                button.style.transition = "0.8s ease";
                setTimeout(function() {
                  result.innerHTML = "";
                  result.style.backgroundColor = "#ffffff";
                  result.style.marginTop = "-4px";
                  result.style.transition = "0.75s";
                }, 750);
              }, 1000);
            }, 1500);
          }
        }, 10);
      }

      fetch("/api/newsletter", {
        method: "POST",
        headers: { "Content-Type": "text/plain" },
        body: JSON.stringify({ email: email })
      })
      .then(async res => {
        if (res.status === 200) {
          success();
        } else {
          error();
        }
      })
      .catch(async err => {
        console.error(err);
        error();
      });
    });
  });

  return(
    <section id="connect">
      <h1>CONNECT</h1>
      <div className="connect-content-wrapper">
        <div className="connect-left">
          <h2>NEWSLETTER</h2>
          <p>SIGN UP TO THE NEWSLETTER AND RECEIVE EMAILS ABOUT NEW AND UPCOMING RELEASES.</p>
          <form id="email-form" method="post">
            <div className="email-input">
              <div className="newsletter-outer">
                <input placeholder="EMAIL" type="email" id="newsletter-email" className="ne" name="newsletter" maxLength={50} required />
                <input type="submit" className="newsletter-button" id="newsletterID" value="" />
              </div>
              <div className="submit-newsletter">
                <span className="submit-inner-newsletter1">SUBMIT</span>
                <span className="submit-inner-newsletter2">SUBMIT</span>
              </div>
              <span id="sendingnewsletter">SENDING...</span>
              <div className="result">
                <div className="result-inner"></div>
              </div>
            </div>
            <span id="errormessagecontact"></span>
          </form>
        </div>
        <div className="connect-right">
          <h2>SOCIAL MEDIA</h2>
          <p>SUPPORT ME OVER ON SOCIAL MEDIA AND TO STAY CONNECTED.</p>
          <div className="social-media-icons">
            <div className="social-media-img">
              <a href="https://facebook.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="Facebook"
                  alt="facebook"
                  src="/images/connect-facebook.png"
                />
              </a>
            </div>
            <div className="social-media-img">
              <a href="https://x.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="X"
                  alt="x"
                  src="/images/connect-x.png"
                />
              </a>
            </div>
            <div className="social-media-img">
              <a href="https://youtube.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="YouTube"
                  alt="youtube"
                  src="/images/connect-youtube.png"
                />
              </a>
            </div>
            <div className="social-media-img">
              <a href="https://rumble.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="Rumble"
                  alt="rumble"
                  src="/images/connect-rumble.png"
                />
              </a>
            </div>
            <div className="social-media-img">
              <a href="https://twitch.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="Twitch"
                  alt="twitch"
                  src="/images/connect-twitch.png"
                />
              </a>
            </div>
            <div className="social-media-img">
              <a href="https://soundcloud.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="SoundCloud"
                  alt="soundcloud"
                  src="/images/connect-soundcloud.png"
                />
              </a>
            </div>
            <div className="social-media-img">
              <a href="https://patreon.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="Patreon"
                  alt="patreon"
                  src="/images/connect-patreon.png"
                />
              </a>
            </div>
            <div className="social-media-img">
              <a href="https://discord.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="Discord"
                  alt="discord"
                  src="/images/connect-discord.png"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}