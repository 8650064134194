import React, { useState } from "react";
import Header from "../components/Header.jsx";
import "../components/styles/Error404.css";

export default function Error404() {
  const [views] = useState(1238);

  return (
    <>
      <Header />
      <div id="error404">
        <img
          src="/images/media-icon-404.svg"
          alt="404"
          width={306}
          height={298}
          draggable="false"
        />
        <h1>OOPS... WHAT JUST HAPPENED?</h1>
        <p>
          Just kidding. Either this page doesn't exist or we forgot to pay the
          coder... again.
        </p>
        <p>
          If you think this error is on our end, feel free to reach out through
          our <a href="/home?=contact">contact form</a> to let me know.
        </p>
        <div>
          <p>In the meantime, you have a few options on what to do:</p>
          <ul>
            <li>Embrace the silence.</li>
            <li>
              Explore the <a href="/media">media page</a> for captivating
              content.
            </li>
            <li>
              Consider making a{" "}
              <a
                href="https://paypal.me/JVKStudios"
                target="_blank"
                rel="noopener noreferrer"
              >
                donation
              </a>{" "}
              to support my passion and help create more music for you.
            </li>
            <li>
              Check out my social media below for the latest updates and
              engaging content.
            </li>
          </ul>
          <div className="social-media-icons">
            <div className="social-media-img">
              <a href="https://facebook.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="Facebook"
                  alt="facebook"
                  src="/images/connect-facebook.png"
                />
              </a>
            </div>
            <div className="social-media-img">
              <a href="https://x.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="X"
                  alt="x"
                  src="/images/connect-x.png"
                />
              </a>
            </div>
            <div className="social-media-img">
              <a href="https://youtube.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="YouTube"
                  alt="youtube"
                  src="/images/connect-youtube.png"
                />
              </a>
            </div>
            <div className="social-media-img">
              <a href="https://rumble.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="Rumble"
                  alt="rumble"
                  src="/images/connect-rumble.png"
                />
              </a>
            </div>
            <div className="social-media-img">
              <a href="https://twitch.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="Twitch"
                  alt="twitch"
                  src="/images/connect-twitch.png"
                />
              </a>
            </div>
            <div className="social-media-img">
              <a href="https://soundcloud.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="SoundCloud"
                  alt="soundcloud"
                  src="/images/connect-soundcloud.png"
                />
              </a>
            </div>
            <div className="social-media-img">
              <a href="https://patreon.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="Patreon"
                  alt="patreon"
                  src="/images/connect-patreon.png"
                />
              </a>
            </div>
            <div className="social-media-img">
              <a href="https://discord.com" target="_blank" rel="noreferrer">
                <img
                  draggable="false"
                  title="Discord"
                  alt="discord"
                  src="/images/connect-discord.png"
                />
              </a>
            </div>
          </div>
          <p>Or better yet...</p>
          <ul>
            <li>
              Commit your heart to Christ, build a relationship with Him, and
              discover a love unlike any other.
            </li>
          </ul>
          <p>
            If you'd like assistance with this, feel free to reach out through
            my <a href="/home?=contact">contact form</a>.
          </p>
          <span>
            {views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} VIEWS
          </span>
        </div>
      </div>
    </>
  );
}
