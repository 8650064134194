import React from "react";
import "./styles/Footer.css";

export default function Footer() {
  return(
    <footer>
      <div className="footer-left">
        &copy; 2024 - {new Date().getFullYear() + 1}
        &nbsp;
        <a href="/">JVK STUDIOS</a>
        . ALL RIGHTS RESERVED.
      </div>
      <div className="footer-center">
        <a href="/legal?=disclaimer">DISCLAIMER</a>
        <div className="footer-seperator"></div>
        <a href="/legal?=privacy">PRIVACY POLICY</a>
        <div className="footer-seperator"></div>
        <a href="/legal?=terms">TERMS OF SERVICE</a>
      </div>
      <div className="footer-right">
        <a href="#root">SITE MAP</a>
        <div className="footer-seperator"></div>
        <a href="#root">
          BACK TO TOP
          <svg viewBox="0 0 13 13" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.58376 2.73002L2.37551 6.93827L1.66841 6.23116L6.7102 1.18937L7.06375 0.835814L7.41731 1.18937L12.6326 6.40469L11.9255 7.11179L7.5846 2.77087L7.73721 11.7916L6.73735 11.8085L6.58376 2.73002Z"
            />
          </svg>
        </a>
      </div>
    </footer>
  );
}
