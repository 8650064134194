import React from "react";
import "./styles/MediaTabs.css";

export default function MediaTabs({
  path,
  currentMedia,
  setCurrentMedia,
  children,
}) {
  return (
    <>
      <div className="media-nav">
        <a href="/media?=latest" onClick={(e) => e.preventDefault()}>
          <button
            onClick={() => {
              setCurrentMedia("latest");
              window.history.pushState("", "", "/media?=latest");
            }}
            id="media-latest"
            className={currentMedia === "latest" ? "media-active" : ""}
          >
            LATEST RELEASES
          </button>
        </a>
        <a href="/media?=albums" onClick={(e) => e.preventDefault()}>
          <button
            onClick={() => {
              setCurrentMedia("albums");
              window.history.pushState("", "", "/media?=albums");
            }}
            id="media-albums"
            className={currentMedia === "albums" || currentMedia === "album" ? "media-active" : ""}
          >
            ALBUMS
          </button>
        </a>
        <a href="/media?=other" onClick={(e) => e.preventDefault()}>
          <button
            onClick={() => {
              setCurrentMedia("other");
              window.history.pushState("", "", "/media?=other");
            }}
            id="media-gallery"
            className={currentMedia === "other" ? "media-active" : ""}
          >
            OTHER
          </button>
        </a>
        <a
          href="https://imdb.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "contents" }}
        >
          <button id="media-personal" className="other">
            RESUME
          </button>
        </a>
      </div>
      <div className="outer">
        <div className="media-main inner">
          <div className="media-content">{children}</div>
        </div>
      </div>
    </>
  );
}
