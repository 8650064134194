import React from "react";
import Header from "../components/Header";
import Legal from "../components/Legal";
import Connect from "../components/Connect";
import Footer from "../components/Footer";

export default function Home() {
  return(
    <>
      <Header />
      <Legal />
      <Connect />
      <Footer />
    </>
  );
}