import React from "react";
import "./styles/MediaHeading.css";

export default function MediaHeading({ href, link, children }) {
  return (
    <div className="heading">
      <button title="Share" onClick={() => navigator.clipboard.writeText(href)}>
        <svg width="11" height="12" viewBox="0 0 13 14" fill="currentColor">
          <path d="M10.5422 9.89558C10.008 9.89558 9.53012 10.1064 9.16466 10.4367L4.15361 7.52008C4.18875 7.35843 4.21687 7.19679 4.21687 7.02811C4.21687 6.85944 4.18875 6.69779 4.15361 6.53614L9.10843 3.64759C9.48795 3.999 9.98695 4.21687 10.5422 4.21687C11.7088 4.21687 12.6506 3.2751 12.6506 2.10843C12.6506 0.941767 11.7088 0 10.5422 0C9.3755 0 8.43373 0.941767 8.43373 2.10843C8.43373 2.27711 8.46185 2.43875 8.49699 2.6004L3.54217 5.48896C3.16265 5.13755 2.66365 4.91968 2.10843 4.91968C0.941767 4.91968 0 5.86145 0 7.02811C0 8.19478 0.941767 9.13655 2.10843 9.13655C2.66365 9.13655 3.16265 8.91867 3.54217 8.56727L8.54618 11.491C8.51104 11.6386 8.48996 11.7932 8.48996 11.9478C8.48996 13.0793 9.41064 14 10.5422 14C11.6737 14 12.5944 13.0793 12.5944 11.9478C12.5944 10.8163 11.6737 9.89558 10.5422 9.89558Z" />
        </svg>
      </button>
      <a
        href={href}
        style={{ display: "contents" }}
        onClick={(e) => {
          e.preventDefault();
          window.scrollBy(0, e.target.getBoundingClientRect().top - 10);
          window.history.pushState("", "", href);
        }}
      >
        <h3>{children}</h3>
      </a>
      <span>
        <a href={link.url} onClick={link.action ? (e) => link.action(e) : null}>
          {link.text}
        </a>
      </span>
    </div>
  );
}
